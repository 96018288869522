export const SEARCH_LIST_SET = 'SEARCH_LIST_SET';

export const DELEGATE_DIALOG_SHOW = 'DELEGATE_DIALOG_SHOW';
export const DELEGATE_DIALOG_HIDE = 'DELEGATE_DIALOG_HIDE';

export const DELEGATE_SUCCESS_DIALOG_SHOW = 'DELEGATE_SUCCESS_DIALOG_SHOW';
export const DELEGATE_SUCCESS_DIALOG_HIDE = 'DELEGATE_SUCCESS_DIALOG_HIDE';

export const DELEGATE_PROCESSING_DIALOG_SHOW = 'DELEGATE_PROCESSING_DIALOG_SHOW';
export const DELEGATE_PROCESSING_DIALOG_HIDE = 'DELEGATE_PROCESSING_DIALOG_HIDE';

export const DELEGATE_FAILED_DIALOG_SHOW = 'DELEGATE_FAILED_DIALOG_SHOW';
export const DELEGATE_FAILED_DIALOG_HIDE = 'DELEGATE_FAILED_DIALOG_HIDE';

export const VALIDATOR_SET = 'VALIDATOR_SET';
export const TO_VALIDATOR_SET = 'TO_VALIDATOR_SET';

export const TOKENS_SET = 'TOKENS_SET';

export const VALIDATORS_FETCH_IN_PROGRESS = 'VALIDATORS_FETCH_IN_PROGRESS';
export const VALIDATORS_FETCH_SUCCESS = 'VALIDATORS_FETCH_SUCCESS';
export const VALIDATORS_FETCH_ERROR = 'VALIDATORS_FETCH_ERROR';

export const VALIDATOR_FETCH_IN_PROGRESS = 'VALIDATOR_FETCH_IN_PROGRESS';
export const VALIDATOR_FETCH_SUCCESS = 'VALIDATOR_FETCH_SUCCESS';
export const VALIDATOR_FETCH_ERROR = 'VALIDATOR_FETCH_ERROR';

export const DELEGATED_VALIDATORS_FETCH_IN_PROGRESS = 'DELEGATED_VALIDATORS_FETCH_IN_PROGRESS';
export const DELEGATED_VALIDATORS_FETCH_SUCCESS = 'DELEGATED_VALIDATORS_FETCH_SUCCESS';
export const DELEGATED_VALIDATORS_FETCH_ERROR = 'DELEGATED_VALIDATORS_FETCH_ERROR';

export const CLAIM_REWARDS_DIALOG_SHOW = 'CLAIM_REWARDS_DIALOG_SHOW';
export const CLAIM_REWARDS_DIALOG_HIDE = 'CLAIM_REWARDS_DIALOG_HIDE';
export const CLAIM_REWARDS_VALIDATOR_SET = 'CLAIM_REWARDS_VALIDATOR_SET';

export const CLAIM_DELEGATE_DIALOG_SHOW = 'CLAIM_DELEGATE_DIALOG_SHOW';
export const CLAIM_DELEGATE_DIALOG_HIDE = 'CLAIM_DELEGATE_DIALOG_HIDE';
export const CLAIM_DELEGATE_VALIDATOR_SET = 'CLAIM_DELEGATE_VALIDATOR_SET';

export const VALIDATOR_IMAGE_FETCH_IN_PROGRESS = 'VALIDATOR_IMAGE_FETCH_IN_PROGRESS';
export const VALIDATOR_IMAGE_FETCH_SUCCESS = 'VALIDATOR_IMAGE_FETCH_SUCCESS';
export const VALIDATOR_IMAGE_FETCH_ERROR = 'VALIDATOR_IMAGE_FETCH_ERROR';

export const INACTIVE_VALIDATORS_FETCH_IN_PROGRESS = 'INACTIVE_VALIDATORS_FETCH_IN_PROGRESS';
export const INACTIVE_VALIDATORS_FETCH_SUCCESS = 'INACTIVE_VALIDATORS_FETCH_SUCCESS';
export const INACTIVE_VALIDATORS_FETCH_ERROR = 'INACTIVE_VALIDATORS_FETCH_ERROR';

export const APR_FETCH_IN_PROGRESS = 'APR_FETCH_IN_PROGRESS';
export const APR_FETCH_SUCCESS = 'APR_FETCH_SUCCESS';
export const APR_FETCH_ERROR = 'APR_FETCH_ERROR';

export const SELECTED_MULTI_VALIDATORS = 'SELECTED_MULTI_VALIDATORS';
