export const config = {
    RPC_URL: 'https://rpc.omniflix.network',
    REST_URL: 'https://rest.omniflix.network',
    EXPLORER_URL: 'https://www.mintscan.io/omniflix',
    STAKING_URL: 'https://flix.omniflix.co/stake',
    NETWORK_NAME: 'OmniFlix',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'omniflixhub-1',
    CHAIN_NAME: 'OmniFlix Hub',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
    COIN_TYPE: 118,
    COSMOSTAION: 'omniflix',
    COINGECKO_ID: 'omniflix-network',
    GAS_PRICE_STEP_LOW: 0.001,
    GAS_PRICE_STEP_AVERAGE: 0.0025,
    GAS_PRICE_STEP_HIGH: 0.025,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
